import React from "react"
import PropTypes from "prop-types"
import Avatar7 from "../../images/avatar/7.jpg"
import { Link } from "gatsby"
const BlogAbout = () => {
  return (
    <div className="widget-wrap fl-wrap">
      <h4 className="widget-title">
        <span>01.</span> Über den Autor
      </h4>
      <div className="widget-container fl-wrap">
        <div className="about-widget fl-wrap">
          <img src={Avatar7} alt="Author Jezek Jan" />
          <h5>
            <Link to="#">Jezek Jan</Link>
          </h5>
          <div className="clearfix"></div>
          <p>
            Jezek Jan ist gelernter Nachrichtenelektroniker, diplomierter
            Freizeit- und Eventmanager sowie abgeschlossener
            Software-as-a-service Entwickler (BUniversity of California,
            Berkeley).
            <Link to="/#sec4">Mehr Infos</Link>
          </p>
        </div>
      </div>
    </div>
  )
}

export default BlogAbout

import React from "react"
import { Link } from "gatsby"

const BlogTags = ({ tags }) => {
  return (
    <div className="widget-wrap fl-wrap">
      <h4 className="widget-title">
        <span>03.</span> Tags
      </h4>
      <div className="widget-container fl-wrap">
        <ul className="tagcloud">
          {tags.map((tag, idx) => {
            var index = tag.edges[0].node.frontmatter.tags.indexOf(
              tag.fieldValue
            )

            return (
              <li key={idx}>
                <Link
                  to={`/tags/${tag.edges[0].node.fields.tags[index]}`}
                  title={`${tag.edges[0].node.frontmatter.tags[index]}`}
                  className="transition link"
                >
                  {tag.fieldValue}
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default BlogTags

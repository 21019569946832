import React from "react"
import { Link } from "gatsby"

const BlogCategories = ({ categories }) => {
  return (
    <div className="widget-wrap fl-wrap">
      <h4 className="widget-title">
        <span>05.</span> Kategorien
      </h4>
      <div className="widget-container fl-wrap">
        <ul className="cat-item">
          {categories.group.map((category, idx) => {
            return (
              <li key={idx}>
                <Link
                  to={`/category/${category.edges[0].node.fields.category}`}
                  title={category.fieldValue}
                >
                  {category.fieldValue}
                </Link>
                <span>({category.totalCount})</span>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default BlogCategories

import React from "react"
import BlogAbout from "./blog-about"
import BlogModule from "./blog-module"
import BlogTags from "./blog-tags"
import BlogCategories from "./blog-categories"
import ContactMe from "../contact/ContactMe"
import BlogInstagram from "./blog-instagram"
import BlogPostAuthor from "./blog-post-author"
import BlogComments from "./blog-comments"
// import blogGallery from "./blogGallery"
import Img from "gatsby-image"
import SliderCarousel from "../slider-carousel/slider-carousel"
import BlogFilter from "./blog-filter"
import BlogNavigation from "./blog-navigation"
import SEO from "../seo"
import { Link } from "gatsby"
import SecLines from "../sec-lines/sec-lines"
import Footer from "../footer/footer"
import GetInTouch from "../get-in-touch/get-in-touch"

const BlogPost = props => {
  const { data, pageContext } = props
  const { categories, tags, latest_posts, related_category } = data
  const post = data.post
  let featuredImgFluid =
    props.data.post.frontmatter.featuredImage.childImageSharp.fluid
  return (
    <>
      <SEO
        image={props.data.post.frontmatter.featuredImage.publicURL}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        url={props.location.href}
      />
      <meta property="og:type" content="article" />
      <meta name="twitter:card" content="summary" />
      <div id="wrapper" className="single-page-wrap">
        <div className="content">
          <div className="single-page-decor"></div>

          <div className="single-page-fixed-row blog-single-page-fixed-row">
            <div className="scroll-down-wrap">
              <div className="mousey">
                <div className="scroller"></div>
              </div>
              <span>Runter scrollen</span>
            </div>
            <BlogFilter />
          </div>

          <section data-scrollax-parent="true" id="sec1">
            <div
              className="section-subtitle left-pos"
              data-scrollax="properties: { translateY: '-250px' }"
            >
              <span>//</span>Post Titel
            </div>
            <div className="container">
              <div className="fl-wrap post-container">
                <div className="row">
                  <div className="col-md-8">
                    <div className="post fl-wrap fw-post">
                      <h1>
                        <Link
                          to={post.fields.slug}
                          title={post.frontmatter.title}
                        >
                          <span>{post.frontmatter.title}</span>
                        </Link>
                      </h1>
                      <div className="parallax-header">
                        <Link to="#">{post.frontmatter.date}</Link>
                        <span>Kategorie : </span>
                        {post.fields.category && (
                          <Link
                            to={`/category/${post.fields.category}`}
                            title={post.fields.category}
                          >
                            {post.fields.category}
                          </Link>
                        )}
                      </div>

                      <Img
                        className="blog-media fl-wrap nomar-bottom"
                        fluid={featuredImgFluid}
                        alt={post.frontmatter.title}
                      />

                      {post.fields.tags ? (
                        <div className="parallax-header fl-wrap">
                          <span>Tags : </span>
                          {post.fields.tags.map((tag, idx) => (
                            <Link key={idx} to={`/tags/${tag}`}>
                              {tag}
                            </Link>
                          ))}
                        </div>
                      ) : null}
                      <div className="blog-text fl-wrap">
                        <div className="clearfix"></div>
                        <h3>{post.frontmatter.subtitle}</h3>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: post.html,
                          }}
                        />
                        {/* <ul className="post-counter single-post-counter">
                          <li>
                            <i className="fa fa-eye"></i>
                            <span>687</span>
                          </li>
                          <li>
                            <i className="fal fa-comments-alt"></i>
                            <span>10</span>
                          </li>
                        </ul> */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="blog-sidebar fl-wrap fixed-bar">
                      <BlogCategories categories={categories} />
                      <BlogTags tags={tags.group} />
                      <BlogModule title="Latest Posts" posts={latest_posts} />
                      <BlogModule
                        title="Related Posts"
                        posts={related_category}
                      />
                      <BlogAbout />
                    </div>
                  </div>

                  <div className="limit-box fl-wrap"></div>
                </div>

                <BlogNavigation {...props} />
              </div>
            </div>
            <SecLines />
          </section>

          <section className="dark-bg2 small-padding order-wrap">
            <div className="container">
              <GetInTouch />
            </div>
          </section>
        </div>

        <div className="height-emulator fl-wrap"></div>
        <Footer />

        <ContactMe />
      </div>
    </>
  )
}

export default BlogPost

import React from "react"
import FolioImage from "../../images/folio/1.jpg"
import Img from "gatsby-image"
import { Link } from "gatsby"

const BlogLatest = ({ title, posts }) => {
  return (
    <div className="widget-wrap fl-wrap">
      <h4 className="widget-title">
        <span>02.</span> {title}
      </h4>
      <div className="widget-container fl-wrap">
        <div className="widget-posts fl-wrap">
          <ul>
            {posts.edges.map((post, idx) => {
              return (
                <li key={idx} className="clearfix">
                  <Link to={post.node.fields.slug} className="widget-posts-img">
                    <Img
                      fluid={
                        post.node.frontmatter.featuredImage.childImageSharp
                          .fluid
                      }
                      alt={post.node.title}
                    />
                  </Link>
                  <div className="widget-posts-descr">
                    <Link to={post.node.fields.slug} title="{post.node.title}">
                      {post.node.frontmatter.title}
                    </Link>
                    <span className="widget-posts-date"> 2 Mar 14.42 </span>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default BlogLatest

import React from "react"
import { graphql } from "gatsby"
import BlogPost from "../components/blog/blog-post"
import Layout from "../components/layout"

const BlogPostTemplate = props => {
  const { location, data, pageContext } = props
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} title={siteTitle}>
      <BlogPost {...props} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $category: String) {
    site: site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    tags: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { title: { ne: "" } }
      }
    ) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              tags
            }
            frontmatter {
              tags
            }
          }
        }
      }
    }
    categories: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { title: { ne: "" } }
      }
    ) {
      group(field: frontmatter___category) {
        fieldValue
        totalCount
        edges {
          node {
            fields {
              category
            }
            frontmatter {
              title
            }
          }
        }
      }
      nodes {
        frontmatter {
          title
          category
        }
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        subtitle
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        date(formatString: "MMMM DD, YYYY")
        description
      }
      fields {
        slug
        category
        tags
      }
    }
    related_category: allMarkdownRemark(
      sort: { order: DESC, fields: frontmatter___date }
      limit: 5
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        fields: { category: { eq: $category } }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 90, maxHeight: 60) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    latest_posts: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/" } }
      sort: { order: DESC, fields: frontmatter___date }
      limit: 3
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 90, maxHeight: 60) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogPostTemplate

import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Transition } from "react-transition-group"
import BlogSearch from "./blog-search"

const duration = 100

const defaultStyleMenu = {
  visibility: "hidden",
  opacity: 0,
  display: "none",
  transition: "visibility 0s, opacity 0.3s linear",
}

const transitionStylesMenu = {
  entering: { visibility: "visible", opacity: 1, display: "block" },
  entered: { visibility: "visible", opacity: 1, display: "block" },
  exiting: { visibility: "hidden", opacity: 0, display: "none" },
  exited: { visibility: "hidden", opacity: 0, display: "none" },
}

const BlogFilter = () => {
  const filterQuery = graphql`
    query {
      categories: allMarkdownRemark(
        filter: { frontmatter: { title: { ne: "" } } }
      ) {
        group(field: frontmatter___category) {
          fieldValue
          totalCount
          edges {
            node {
              fields {
                category
              }
            }
          }
        }
      }
      tags: allMarkdownRemark(filter: { frontmatter: { title: { ne: "" } } }) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
          edges {
            node {
              fields {
                tags
              }
              frontmatter {
                tags
              }
            }
          }
        }
      }
    }
  `
  const categoryResult = useStaticQuery(filterQuery)

  const [categoryClicked, setCategoryState] = useState(false)
  const [tagClicked, setTagState] = useState(false)

  const handleCategoryClicked = e => {
    setCategoryState(!categoryClicked)
  }

  const handleTagClicked = e => {
    setTagState(!tagClicked)
  }
  return (
    <div className="blog-filters">
      <span>Filtern nach: </span>

      <div className="tag-filter blog-btn-filter">
        <div className="blog-btn" onClick={handleTagClicked}>
          Tags <i className="fa fa-tags" aria-hidden="true"></i>
        </div>
        <Transition in={tagClicked} timeout={duration}>
          {state => (
            <ul
              style={{
                ...defaultStyleMenu,
                ...transitionStylesMenu[state],
                visibility: "visible",
              }}
            >
              {categoryResult.tags.group.map((tag, idx) => {
                var index = tag.edges[0].node.frontmatter.tags.indexOf(
                  tag.fieldValue
                )

                return (
                  <li key={idx}>
                    <Link
                      to={`/tags/${tag.edges[0].node.fields.tags[index]}`}
                      className="transition link"
                    >
                      {tag.fieldValue}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
        </Transition>
      </div>

      <div className="category-filter blog-btn-filter">
        <div className="blog-btn" onClick={handleCategoryClicked}>
          Kategorien <i className="fa fa-list-ul" aria-hidden="true"></i>
        </div>
        <Transition in={categoryClicked} timeout={duration}>
          {state => (
            <ul
              style={{
                ...defaultStyleMenu,
                ...transitionStylesMenu[state],
                visibility: "visible",
              }}
            >
              {categoryResult.categories.group.map((category, idx) => {
                return (
                  <li key={idx}>
                    <Link
                      to={`/category/${category.edges[0].node.fields.category}`}
                    >
                      {category.fieldValue}
                    </Link>
                  </li>
                )
              })}
            </ul>
          )}
        </Transition>
      </div>

      {/* <BlogSearch /> */}
    </div>
  )
}

export default BlogFilter

import React, { Component } from "react"
import SimpleSlider from "react-slick"
import Slide from "./slide"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons"
import "./slider-carousel.css"
class SliderCarousel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeSlide: 2,
    }
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }

  next() {
    this.slider.slickNext()
  }

  previous() {
    this.slider.slickPrev()
  }
  render() {
    var sliderGallerySettings = {
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: true,
      dots: true,
      slide: "row",
      arrows: false,
      slickCurrentSlide: 2,
      centerPadding: "0",
      centerMode: true,
      initialSlide: 2,
      beforeChange: (current, next) => {
        this.setState({ activeSlide: next })
      },

      responsive: [
        {
          breakpoint: 1224,
          settings: {
            slidesToShow: 4,
            centerMode: false,
          },
        },

        {
          breakpoint: 1084,
          settings: {
            slidesToShow: 2,
            centerMode: true,
          },
        },

        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerMode: true,
          },
        },
      ],
      className: "fet_pr-carousel cur_carousel-slider-container",
    }

    return (
      <>
        <div className="slider-carousel-wrap fl-wrap">
          <SimpleSlider
            ref={slider => (this.slider = slider)}
            {...sliderGallerySettings}
          >
            {this.props.projects.nodes.map((slide, idx) => {
              return <Slide key={idx} slide={slide} />
            })}
          </SimpleSlider>
          <div className="sp-cont sp-arr sp-cont-prev" onClick={this.previous}>
            <FontAwesomeIcon icon={faLongArrowAltLeft} />
          </div>
          <div className="sp-cont sp-arr sp-cont-next" onClick={this.next}>
            <FontAwesomeIcon icon={faLongArrowAltRight} />
          </div>
        </div>
        <div className="fet_pr-carousel-counter">
          {this.state.activeSlide + 1} / {this.props.projects.nodes.length}
        </div>
      </>
    )
  }
}

export default props => {
  return <SliderCarousel {...props} />
}
